export { default as AntDesign } from "./AntDesign";
export { default as Entypo } from "./Entypo";
export { default as EvilIcons } from "./EvilIcons";
export { default as Feather } from "./Feather";
export { default as Fontisto } from "./Fontisto";
export { default as FontAwesome } from "./FontAwesome";
export { default as FontAwesome5 } from "./FontAwesome5";
export { default as Foundation } from "./Foundation";
export { default as Ionicons } from "./Ionicons";
export { default as MaterialCommunityIcons } from "./MaterialCommunityIcons";
export { default as MaterialIcons } from "./MaterialIcons";
export { default as Octicons } from "./Octicons";
export { default as SimpleLineIcons } from "./SimpleLineIcons";
export { default as Zocial } from "./Zocial";
export { default as createMultiStyleIconSet } from "./createMultiStyleIconSet";
export { default as createIconSet } from "./createIconSet";
export { default as createIconSetFromFontello } from "./createIconSetFromFontello";
export { default as createIconSetFromIcoMoon } from "./createIconSetFromIcoMoon";
export { default as createMu } from "./createIconSetFromIcoMoon";
